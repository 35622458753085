import WorkflowTicketBreadcrumbs from '@/components/Workflow/WorkflowTicketBreadcrumbs/WorkflowTicketBreadcrumbs.vue'
import WorkflowDetails from '@/components/Workflow/WorkflowDetails/WorkflowDetails.vue'

export default {
  props: ['id'],
  components: {
    WorkflowTicketBreadcrumbs,
    WorkflowDetails
  },
  data() {
    return {
      workflowTicket: {}
    }
  },
  methods: {
    updateWorkflowTicket(workflowTicket) {
      this.workflowTicket = workflowTicket
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
